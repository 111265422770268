exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-customer-stories-acalvio-tsx": () => import("./../../../src/pages/customer-stories/acalvio.tsx" /* webpackChunkName: "component---src-pages-customer-stories-acalvio-tsx" */),
  "component---src-pages-embed-footer-ts": () => import("./../../../src/pages/embed/footer.ts" /* webpackChunkName: "component---src-pages-embed-footer-ts" */),
  "component---src-pages-embed-pricing-ts": () => import("./../../../src/pages/embed/pricing.ts" /* webpackChunkName: "component---src-pages-embed-pricing-ts" */),
  "component---src-pages-hack-checker-tsx": () => import("./../../../src/pages/hack-checker.tsx" /* webpackChunkName: "component---src-pages-hack-checker-tsx" */),
  "component---src-pages-payment-active-users-billing-ts": () => import("./../../../src/pages/payment/active-users-billing.ts" /* webpackChunkName: "component---src-pages-payment-active-users-billing-ts" */),
  "component---src-pages-payment-active-users-ts": () => import("./../../../src/pages/payment/active-users.ts" /* webpackChunkName: "component---src-pages-payment-active-users-ts" */),
  "component---src-pages-payment-enterprise-ts": () => import("./../../../src/pages/payment/enterprise.ts" /* webpackChunkName: "component---src-pages-payment-enterprise-ts" */),
  "component---src-pages-payment-failed-index-ts": () => import("./../../../src/pages/payment/failed/index.ts" /* webpackChunkName: "component---src-pages-payment-failed-index-ts" */),
  "component---src-pages-payment-failed-redirect-ts": () => import("./../../../src/pages/payment/failed/redirect.ts" /* webpackChunkName: "component---src-pages-payment-failed-redirect-ts" */),
  "component---src-pages-payment-free-ts": () => import("./../../../src/pages/payment/free.ts" /* webpackChunkName: "component---src-pages-payment-free-ts" */),
  "component---src-pages-payment-success-ts": () => import("./../../../src/pages/payment/success.ts" /* webpackChunkName: "component---src-pages-payment-success-ts" */),
  "component---src-pages-payment-workspace-ts": () => import("./../../../src/pages/payment/workspace.ts" /* webpackChunkName: "component---src-pages-payment-workspace-ts" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-features-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/features.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-features-mdx" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-index-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/index.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-index-mdx" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-payment-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/payment.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-payment-mdx" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-records-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/records.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-records-mdx" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-security-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/security.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-security-mdx" */),
  "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-users-mdx": () => import("./../../../src/templates/faq.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/faq/en/users.mdx" /* webpackChunkName: "component---src-templates-faq-tsx-content-file-path-src-content-faq-en-users-mdx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-legal-document-tsx-content-file-path-src-content-legal-documents-en-privacy-policy-mdx": () => import("./../../../src/templates/legal-document.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/legal-documents/en/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-legal-document-tsx-content-file-path-src-content-legal-documents-en-privacy-policy-mdx" */),
  "component---src-templates-legal-document-tsx-content-file-path-src-content-legal-documents-en-terms-and-conditions-mdx": () => import("./../../../src/templates/legal-document.tsx?__contentFilePath=/var/lib/gitlab-runner/builds/mBENNoof/0/Web/passwd-microsite/src/content/legal-documents/en/terms-and-conditions.mdx" /* webpackChunkName: "component---src-templates-legal-document-tsx-content-file-path-src-content-legal-documents-en-terms-and-conditions-mdx" */)
}

