import Cookies from 'js-cookie';
import { addYears } from 'date-fns/addYears';

// These cookies are used in the Passwd application too to send campaing data to
// BE to track user conversions in situations where the user uses demo.passwd.team
// or app.passwd.team
const originalUtmParams = 'passwd_utm_original';
const finalUtmParams = 'passwd_utm_final';

enum UTMKey {
    SOURCE = 'utm_source',
    MEDIUM = 'utm_medium',
    CAMPAIGN = 'utm_campaign',
}

type UTMParams = Record<UTMKey, string>;

export function updateCampaignCookies() {
    // eslint-disable-next-line compat/compat
    const query = new URLSearchParams(document.location.search);

    /* eslint-disable camelcase */
    const utm_source = query.get(UTMKey.SOURCE);
    const utm_campaign = query.get(UTMKey.CAMPAIGN);
    const utm_medium = query.get(UTMKey.MEDIUM);

    if (utm_source && utm_campaign && utm_medium) {
        const cookies = Cookies.withAttributes({
            expires: addYears(new Date(), 1),
            domain: process.env.NODE_ENV === 'production' ? 'passwd.team' : undefined,
        });

        const params: UTMParams = {
            utm_source,
            utm_medium,
            utm_campaign,
        };

        if (!cookies.get(originalUtmParams)) {
            cookies.set(originalUtmParams, JSON.stringify(params));
        }

        cookies.set(finalUtmParams, JSON.stringify(params));
    }

    /* eslint-enable camelcase */
}

function ensureUtmParams(params: any) {
    if (Object.keys(params).length === 3 && params[UTMKey.SOURCE] && params[UTMKey.MEDIUM] && params[UTMKey.CAMPAIGN]) {
        return params as UTMParams;
    }

    throw new Error("Utm params don't have all the required fields.");
}

export interface UTMParamsTuple {
    original: UTMParams;
    final: UTMParams;
}

export function getUtmParams(): UTMParamsTuple {
    const originalUtmCookie = Cookies.get(originalUtmParams);
    const finalUtmCookie = Cookies.get(finalUtmParams);

    // Both cookies should be set, if parsing one of them fails,
    // we don't want to use any of them
    try {
        return {
            original: ensureUtmParams(JSON.parse(originalUtmCookie)),
            final: ensureUtmParams(JSON.parse(finalUtmCookie)),
        };
    } catch (e) {}

    return undefined;
}
