import type { ReactNode } from 'react';
import { useEffect } from 'react';

export interface HTMLLanguageProviderProps {
    language: string;
    children: ReactNode;
}

export const HTMLLanguageProvider = ({ language, children }: HTMLLanguageProviderProps) => {
    useEffect(() => {
        if (language && document.documentElement.lang !== language) {
            document.documentElement.setAttribute('lang', language);
        }
    }, [language]);

    return <>{children}</>;
};
