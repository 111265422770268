import { createRenderer } from 'fela';
import { RendererProvider, ThemeProvider } from 'react-fela';

import type { GatsbyBrowser } from 'gatsby';

import config from './fela.config';

import theme from './src/styles/theme';
import { updateCampaignCookies } from './src/utils/utm';

import { HTMLLanguageProvider } from './src/components/HTMLLanguageProvider';

import './src/styles/index.css';

const renderer = createRenderer(config);

const Gatsby: GatsbyBrowser = {
    async onClientEntry() {
        if (typeof IntersectionObserver === `undefined`) {
            await import(`intersection-observer`);
        }

        /* Support intl in older browsers */
        if (!window.Intl) {
            await import(`intl`);
        }
    },
    async onInitialClientRender() {
        await import('url-search-params-polyfill');

        updateCampaignCookies();
    },
    wrapPageElement({ element, props }) {
        const pageContext = props.pageContext as {
            language: string;
        };

        return <HTMLLanguageProvider language={pageContext.language}>{element}</HTMLLanguageProvider>;
    },
    wrapRootElement({ element }) {
        return (
            <ThemeProvider theme={theme}>
                <RendererProvider rehydrate renderer={renderer}>
                    {element}
                </RendererProvider>
            </ThemeProvider>
        );
    },
    shouldUpdateScroll({ routerProps: { location }, getSavedScrollPosition }) {
        if (Boolean(location.hash)) {
            return location.hash.replace('#', '');
        }

        const coords = getSavedScrollPosition(location);

        // Defer scroll to to the next tick to avoid layout shift blocking scroll
        setTimeout(() => {
            window.scrollTo({
                top: coords[1],
                left: coords[0],
                behavior: 'smooth',
            });
        }, 0);

        return false;
    },
};

const { onClientEntry, onInitialClientRender, wrapPageElement, wrapRootElement, shouldUpdateScroll } = Gatsby;

export { onClientEntry, onInitialClientRender, wrapPageElement, wrapRootElement, shouldUpdateScroll };
