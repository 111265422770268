import unit from 'fela-plugin-unit';
import namedKeys from 'fela-plugin-named-keys';
import fallbackValue from 'fela-plugin-fallback-value';
import felaPluginEmbedded from 'fela-plugin-embedded';
import extend from 'fela-plugin-extend';

import sortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';

const namedKeysPlugin = namedKeys({
    // Max widths
    maxWidth1920: '@media (max-width: 1920px)',
    maxWidth1679: '@media (max-width: 1679px)',
    maxWidth1439: '@media (max-width: 1439px)',
    maxWidth1199: '@media (max-width: 1199px)',
    maxWidth1023: '@media (max-width: 1023px)',
    maxWidth767: '@media (max-width: 767px)',
    maxWidth480: '@media (max-width: 480px)',
    maxWidth375: '@media (max-width: 375px)',
    // Min widths
    minWidth481: '@media (min-width: 481px)',
    minWidth768: '@media (min-width: 768px)',
    minWidth1024: '@media (min-width: 1024px)',
    minWidth1200: '@media (min-width: 1200px)',
    minWidth1440: '@media (min-width: 1440px)',
    minWidth1680: '@media (min-width: 1680px)',
    minWidth1921: '@media (min-width: 1921px)',

    // Max Heights
    maxHeight599: '@media (max-height: 599px)',
    // Min Heights
    minHeight600: '@media (min-height: 600px)',
});

const devMode = process.env.NODE_ENV !== 'production';
const plugins = [felaPluginEmbedded(), namedKeysPlugin, fallbackValue(), unit(), extend()];
const enhancers = [sortMediaQueryMobileFirst()];

export default {
    devMode,
    plugins,
    enhancers,
};
